<template>
  <v-container fluid class="contact pa-0">
    <!-- =======================================================
                                Snackbar
    ============================================================-->
    <v-snackbar v-model="snackbar.active">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar.active = false">Cancelar</v-btn>
      </template>
    </v-snackbar>

    <v-row class="d-flex justify-center align-center pt-10">
      <v-col cols="12" md="8" lg="5" xl="5" class="pa-2">
        <v-card class="pa-10">
          <v-col cols="12">
            <h2 class="text-uppercase text-center font-weight-black pb-8">envie-nos um email</h2>

            <v-text-field outlined label="Nome" v-model="name" />

            <v-text-field outlined label="E-mail" v-model="email" />

            <v-text-field
              outlined
              label="Telefone"
              type="tel"
              pattern="[0-9]{2}-[0-9]{5}-[0-9]{4}"
              v-model="phone"
            />

            <v-textarea outlined class="mb-6" label="Descrição" v-model="message" />

            <v-btn outlined class="py-6 primary white--text" @click="create()">Enviar mensagem</v-btn>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import contactService from "../../../../service/contactsService";

export default {
  name: "Contact",
  inject: ["theme"],
  metaInfo: {
    title: "SEEB | Contato",
    meta: [{ name: "description", content: "Deixe sua mensagem" }],
  },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      message: "",
      openDialog: false,
      snackbar:{
        text:"",
        active:false
      }
    };
  },
  methods: {
    create() {
      if (!this.name || !this.email || !this.phone) {
        this.snackbar.text = "Dados insuficientes, favor completar todos os campos."
        this.snackbar.active = true
        return;
      }

      const regexEmail = new RegExp(/^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i);
      if (this.email && !regexEmail.test(this.email)) {
        this.snackbar.text = "Email inválido."
        this.snackbar.active = true
        return;
      }

      contactService
        .create({
          name: this.name,
          email: this.email,
          phone: this.phone,
          message: this.message,
        })
        .then((res) => {
          this.name = "";
          this.email = "";
          this.phone = "";
          this.message = "";

        this.snackbar.text = "Mensagem enviada com sucesso!"
        this.snackbar.active = true
        });
    },
  },
  components: {
    Footer: () => import("@/layouts/web/commons/Footer.vue"),
  },
};
</script>

<style lang="sass" scoped>
</style>
